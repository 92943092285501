import React from 'react';
import useDfpSlot from './useDfpSlot';
import moment from 'moment';

const App = (props) => {
    const idContainer = moment.now();

    useDfpSlot({
        path: props.properties.path,
        size: props.properties.size,
        id: props.properties.id,
        idContainer
    });

    return (
        <div
            className="container-fluid container-adsense center-block"
            id={props.properties.id}
            style={{ width: '100', height: props.properties.size[1], marginTop: 10, marginBottom: 10 }}
        />
    );
};

export default App;