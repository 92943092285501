const firebaseConfig = {
  apiKey: "AIzaSyAJoxv-dldbVYzO1_uPwxWmZzBQJuEZgdE",
  authDomain: "office.conectaapp.cl",
  databaseURL: "https://conecta-app-821b1.firebaseio.com",
  projectId: "conecta-app-821b1",
  storageBucket: "conecta-app-821b1.appspot.com",
  messagingSenderId: "309007118566",
  appId: "1:309007118566:web:617157771cc1a5f8f34b27"
};
// Cloud Functions
//const backendUrl = `https://us-east1-${
//firebaseConfig.projectId
//}.cloudfunctions.net/api/api`;

const backendUrl = `https://office.conectaapp.cl/api`;
const accessToken = '901dded8fff98e266dfa0e0498efc3b48969351fd9b6ae95b01611927a1b8c61';

const accessTokenMultiSiteArcatel = '6e2Rf5SEFu*je?$p*#mpfc=?Xr9&Y*yfTzFx$VGw!X?2PqYx2L';
const backendUrlMultiSiteArcatel = `https://api-ws-multisite.arcatelmultisite.cl/api`;

// App Engine
// const backendUrl = `<insert app engine url here>`;

export default {
  firebaseConfig,
  backendUrl,
  accessToken,
  accessTokenMultiSiteArcatel,
  backendUrlMultiSiteArcatel
};
