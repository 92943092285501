import React, { Component, createElement } from 'react'
import 'regenerator-runtime/runtime';
import PropTypes from 'prop-types'
import MediaPlayer from './MediaPlayer'
import { Media, Player, controls } from 'react-media-player'
import firebaseInitializer from './utils/firebaseInitializer';
import { AuthToken } from './utils/authToken';
import { authAxios, authAxiosArcatelMultiSite } from './utils/hooks';
import moment from 'moment';
import MediaSession from '@mebtte/react-media-session';
import _ from 'lodash';
import './main.scss';
import BeatLoader from 'react-spinners/BeatLoader';
import UseDfp from './utils/useDfp';

const { PlayPause } = controls
const mod = (num, max) => ((num % max) + max) % max

class Playlist extends Component {
  _handleTrackClick(track) {
    this.props.onTrackClick(track)
  }

  render() {
    const { tracks, currentTrack, profile, rowsBanners } = this.props;
    return (
      <div className="media-playlist" style={{ backgroundColor: '#131313', height: window.innerWidth > 768 ? '100vh' : '70vh' }}>
        <header className="media-playlist-header">
          <h3 style={{ fontSize: 18, marginTop: 10 }} className="media-playlist-title"> <img style={{ width: 25, marginRight: 10 }} src="../logo.png"></img> Lista de Reproducción</h3>
        </header>
        <div className="row">
          {rowsBanners.map((row) => {
            return row.map((item, index) => {
              return item.tipo === 'Ads' ?
                <UseDfp key={index} properties={{
                  path: item.slot,
                  size: [item.width, item.height],
                  id: item.client,
                }} /> : null
            })
          })}
        </div>

        <ul className="media-playlist-tracks">
          {tracks.filter(item => item.type !== 'publicity').map((track, index) => (
            <li
              key={track.key}
              className={'media-playlist-track'}
              style={{ color: track.src === currentTrack.src ? profile.color : null }}
              onClick={() => this._handleTrackClick(track)}
            >

              <div className="row">
                {track.src ?
                  <div className="col-3 col-lg-2">
                    <img style={{ width: '100%', marginRight: 10 }} src={track.picture ? track.picture : profile.foto.map(item => item.publicUrl)[0]}></img>
                  </div>
                  :
                  <div className="col-2 col-lg-2">
                    <img style={{ width: '100%', marginRight: 10 }} src={track.picture ? track.picture : profile.foto.map(item => item.publicUrl)[0]}></img>
                  </div>
                }

                {track.src ?
                  <div className="d-flex justify-content-start align-items-center col-9 col-lg-10">
                    {track.label}
                  </div>
                  :
                  <div className="d-flex justify-content-start align-items-center col-10 col-lg-10">
                    {track.label}
                  </div>
                }

              </div>

            </li>
          ))}
        </ul>
      </div>
    )
  }
}

export default class App extends Component {
  state = {
    currentTrack: { src: null, label: 'No media loaded' },
    showMediaPlayer: true,
    repeatTrack: false,
    autoPlay: true,
    profile: null,
    error: false,
    settings: null,
    avisos: [],
    colaAvisos: [],
    countAvisosLoop: 0,
    countMusicaLoop: 0,
    playlist: [],
    playlistMusic: [],
    loading: true,
    rowsBanners: [],
  }

  _handleTrackClick = track => {

    if (track.src) {
      this.setState({ currentTrack: track })
    }
  }

  _navigatePlaylist = direction => {
    const { playlist } = this.state;

    const newIndex = mod(
      playlist.indexOf(this.state.currentTrack) + direction,
      playlist.length
    )
    this.setState({ currentTrack: playlist[newIndex] })
  }

  async componentDidMount() {
    firebaseInitializer();
    await AuthToken.get();

    try {

      const targetmedio = window.location.search.replace('?', "");

      const settings = await authAxios.get('/settings');
      this.setState({ settings: settings.data });

      const profile = await authAxios.get('/medio', {
        params: {
          filter: {
            nombrePlayerWeb: targetmedio
          }
        }
      });

      if (profile.data.count === 0) {
        this.setState({ error: true });

      }

      if (profile.data.rows.length > 0) {
        this.setState({ profile: profile.data.rows[0] });

        document.title = "ConectaApp - " + profile.data.rows[0].nombreMedio;

        this.setState({
          currentTrack: {
            type: 'streaming',
            src: profile.data.rows[0].radio,
            label: profile.data.rows[0].nombreMedio,
            execution: moment(),
            duration: 0
          },
        });

        this.setState({ loading: false });

        let url = profile.data.rows[0].web.replace('http://', '');
        url = url.replace('https://', '');

        const tenant = await authAxiosArcatelMultiSite.get(`/tenant/url`, {
          params: { url },
        });

        if (tenant.data) {
          const banners = await authAxiosArcatelMultiSite.get(`/tenant/${tenant.data.id}/seccion`, {
            params: {
              filter: {
                activo: true,
                ubicacion: 'ConectaPublicidad'
              }
            }
          });

          const rowsBanners = await Promise.all(banners.data.rows.map((item) => {
            return item.banners.map((banner) => banner)
          }));

          this.setState({ rowsBanners });
        }

        await this.syncAnuncios();
        this.loadAnuncios();
        this.syncIcecast();
        this.processAnuncios();

        setInterval(() => {
          this.syncIcecast();
        }, 5000);

        if (this.state.currentTrack.type === "music") {
          return;
        }

        setInterval(() => {

          if (this.state.currentTrack.type === "music") {
            return;
          }

          this.processAnuncios();

        }, 5000);

        setInterval(() => {
          if (this.state.playlist.filter((item) => item.reproduced === false).length === 0) {
            this.setState({
              countAvisosLoop: 0
            });

            this.loadAnuncios();
          }
        }, this.state.settings.cantidadTiempoSegundosParaReproduccionAvisosStreaming * 1000);
      }

    } catch (error) {
      console.log(error);

      this.setState({ loading: false });
    }
  }

  processAnuncios = async () => {
    if (this.state.playlist.filter((item) => item.reproduced === false).length === 0) {
      if (moment().diff(this.state.currentTrack.execution, 'seconds') >= this.state.currentTrack.duration) {
        this.setState({
          currentTrack: {
            type: 'streaming',
            src: this.state.profile.radio,
            label: this.state.profile.nombreMedio,
            execution: moment(),
            duration: this.state.settings.cantidadTiempoSegundosParaReproduccionAvisosStreaming,
          },
        });
      }
    }

    if (this.state.countAvisosLoop >= this.state.settings.cantidadAvisosParaTandaPublicitaria) {
      if (moment().diff(this.state.currentTrack.execution, 'seconds') >= this.state.currentTrack.duration) {
        this.setState({
          currentTrack: {
            type: 'streaming',
            src: this.state.profile.radio,
            label: this.state.profile.nombreMedio,
            execution: moment(),
            duration: this.state.settings.cantidadTiempoSegundosParaReproduccionAvisosStreaming,
          },
          countAvisosLoop: 0
        });
      }
    }

    if (this.state.countAvisosLoop < this.state.settings.cantidadAvisosParaTandaPublicitaria) {
      this.state.playlist.map((item, index) => {
        if (item.type === 'publicity' && item.reproduced === false) {
          if (moment().diff(this.state.currentTrack.execution, 'seconds') >= this.state.currentTrack.duration) {
            this.setState({
              currentTrack: {
                ...item,
                execution: moment(),
              },
              countAvisosLoop: this.state.countAvisosLoop + 1
            });

            const playlistClone = this.state.playlist;
            playlistClone[index].reproduced = true;
            playlistClone[index].execution = moment();

            this.setState({
              playlist: playlistClone
            })
          }
        }
      })
    }
  }

  shouldComponentUpdate(nextProps, nextState) {

    if ('mediaSession' in navigator) {

      if (nextState.currentTrack && this.state.profile) {
        navigator.mediaSession.metadata = new window.MediaMetadata({
          title: nextState.currentTrack.song ? nextState.currentTrack.song : nextState.currentTrack.label,
          artist: nextState.currentTrack.artist ? nextState.currentTrack.artist : '',
          artwork: [
            { src: nextState.currentTrack.picture ? nextState.currentTrack.picture : this.state.profile.foto.map(item => item.publicUrl), sizes: '96x96', type: 'image/png' },
            { src: nextState.currentTrack.picture ? nextState.currentTrack.picture : this.state.profile.foto.map(item => item.publicUrl), sizes: '128x128', type: 'image/png' },
            { src: nextState.currentTrack.picture ? nextState.currentTrack.picture : this.state.profile.foto.map(item => item.publicUrl), sizes: '192x192', type: 'image/png' },
            { src: nextState.currentTrack.picture ? nextState.currentTrack.picture : this.state.profile.foto.map(item => item.publicUrl), sizes: '256x256', type: 'image/png' },
            { src: nextState.currentTrack.picture ? nextState.currentTrack.picture : this.state.profile.foto.map(item => item.publicUrl), sizes: '384x384', type: 'image/png' },
            { src: nextState.currentTrack.picture ? nextState.currentTrack.picture : this.state.profile.foto.map(item => item.publicUrl), sizes: '512x512', type: 'image/png' },
          ]
        });

        navigator.mediaSession.setActionHandler('play', function () { /* Code excerpted. */ });
        navigator.mediaSession.setActionHandler('pause', function () { /* Code excerpted. */ });
        navigator.mediaSession.setActionHandler('stop', function () { /* Code excerpted. */ });
        navigator.mediaSession.setActionHandler('seekbackward', function () { /* Code excerpted. */ });
        navigator.mediaSession.setActionHandler('seekforward', function () { /* Code excerpted. */ });
        navigator.mediaSession.setActionHandler('seekto', function () { /* Code excerpted. */ });
        navigator.mediaSession.setActionHandler('previoustrack', function () { /* Code excerpted. */ });
        navigator.mediaSession.setActionHandler('nexttrack', function () { /* Code excerpted. */ });
      }
    }


    return true;
  }

  syncIcecast = async () => {
    fetch('https://streaming.conectaapp.cl/status-json.xsl')
      .then((response) => {
        return response.json()
      })
      .then((data) => {
        if (!data)
          return;

        data.icestats.source.map(async (source) => {

          if (source.server_url === this.state.profile.serverUrl) {
            if (source.title) {

              if (this.state.currentTrack.type === 'streaming') {
                this.setState({
                  currentTrack: {
                    ...this.state.currentTrack,
                    label: this.state.profile.nombreMedio + ' - ' + decodeURIComponent(escape((source.title))).toUpperCase()
                  }
                })
              }


              if ((this.state.playlistMusic.filter((item) => item.label === decodeURIComponent(escape((source.title))).toUpperCase())).length === 0) {
                let playlistMusicInOrder = _.orderBy(this.state.playlistMusic, 'execution', 'desc');

                const splited = decodeURIComponent(escape((source.title))).toUpperCase().split(' - ');
                let picture = null;
                let src = null;

                if (splited.length === 2) {
                  const artist = splited[0];
                  let song = splited[1];
                  let positionFind = song.indexOf(' (');


                  if (positionFind > -1) {
                    song = song.slice(0, positionFind - 1);
                  }

                  const music = await authAxios.get('/musica', {
                    params: {
                      filter: {
                        title: song,
                        performer: artist
                      }
                    }
                  });

                  if (music.data.count > 0) {
                    let dataInfo = music.data.rows[0];

                    picture = dataInfo.imagenArtist.map((item) => item.publicUrl)[0];
                    src = dataInfo.fileUrl;
                  }

                  playlistMusicInOrder.push({
                    execution: moment(),
                    key: moment(),
                    song,
                    artist,
                    position: playlistMusicInOrder.length + 1,
                    type: 'music',
                    src,
                    picture,
                    label: decodeURIComponent(escape((source.title))).toUpperCase()
                  });
                }

                this.setState({
                  playlistMusic: _.orderBy(playlistMusicInOrder, 'execution', 'desc')
                })
              }
            }
          }
        })
      })
  }

  syncAnuncios = async () => {
    const avisos = await authAxios.get('/aviso', {
      params: {
        filter: {
          fechaInicio: [moment().format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')],
          fechaFin: [moment().format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')],
          cliente: this.state.profile.id
        },
        orderBy: 'createdAt_DESC'
      }
    });

    if (avisos.data.count > 0) {
      this.setState({ avisos: avisos.data.rows });
    }
  }

  loadAnuncios = async () => {
    const { settings } = this.state;

    const { avisos, colaAvisos, profile, countAvisosLoop, playlist } = this.state;
    if (avisos.length > 0) {

      avisos.map((aviso) =>
        aviso.programacion.map((programacion) =>
          programacion.diaSemana.map((dia) => {
            if (dia.codigo === moment().day()) {
              var format = 'HH:mm:ss'
              let currentTime = moment(moment().format(format), format);
              let beforeTime = moment(moment(programacion.horaInicio).format(format), format);
              let afterTime = moment(moment(programacion.horaFin).format(format), format);

              if (currentTime.isBetween(beforeTime, afterTime)) {

                let isExecuted = false;
                let isExecutable = false;

                let playlistForCheck = this.state.playlist.filter((item) => item.id === aviso.id);
                playlistForCheck = _.orderBy(playlistForCheck, 'execution', 'desc');

                if (playlistForCheck.length > 0) {
                  if (playlistForCheck[0].execution) {
                    isExecuted = true;

                    if (moment().diff(playlistForCheck[0].execution, 'seconds') >= settings.cantidadTiempoSegundosParaMismoAviso) {
                      isExecutable = true;
                    }
                  }
                }

                if (isExecuted === false || (isExecuted === true && isExecutable === true)) {
                  if (aviso.tipoAviso === 'Audio') {
                    playlist.push({
                      position: playlist.length + 1,
                      type: 'publicity',
                      key: aviso.id + moment(),
                      id: aviso.id,
                      src: aviso.audioStreaming,
                      label: aviso.titulo,
                      reproduced: false,
                      duration: aviso.duracion
                    });
                  }

                  if (aviso.tipoAviso === 'Video') {
                    playlist.push({
                      position: playlist.length + 1,
                      type: 'publicity',
                      key: aviso.id + moment(),
                      id: aviso.id,
                      src: aviso.video,
                      label: aviso.titulo,
                      reproduced: false,
                      duration: aviso.duracion
                    });
                  }

                  this.setState(playlist)
                }
              }
            }
          })
        )
      );
    }
  }

  loadEnded = async (currentTrack) => {
    if (currentTrack.type === 'music') {
      this.setState({
        countMusicaLoop: this.state.countMusicaLoop + 1
      });

      this.setState({
        currentTrack: {
          type: 'streaming',
          src: this.state.profile.radio,
          label: this.state.profile.nombreMedio,
          execution: moment(),
          duration: this.state.settings.cantidadTiempoSegundosParaReproduccionAvisosStreaming,
        },
      });

      /*
      const { playlistMusic } = this.state;

      const newIndex = mod(
        playlistMusic.indexOf(this.state.currentTrack) + 1,
        playlistMusic.length
      );

      this.setState({ currentTrack: playlistMusic[newIndex] })
      */

    }
  }

  render() {
    const { showMediaPlayer, currentTrack, repeatTrack, autoPlay, profile, error, avisos, playlist, countMusicaLoop, loading, rowsBanners } = this.state

    if (error)
      return (
        <div className="row">
          <div style={{ backgroundColor: 'black', height: '100vh', textAlign: 'center' }} className="container">
            <img style={{ width: 300, marginTop: 150 }} src="./logo.png"></img>
            <h2 style={{ color: 'white', marginTop: 40 }}>Lo sentimos no hemos encontrado lo que buscas...</h2>
          </div>
        </div>
      );


    if (loading) {
      return (
        <div className="row" style={{ height: '100vh' }}>
          <div className="d-flex justify-content-center align-items-center">
            <BeatLoader
              sizeUnit={"px"}
              size={window.innerWidth * 0.04}
              color={'#E4000E'}
              loading={true}
            />
          </div>
        </div>
      )
    }


    return (
      <div className="row">
        {showMediaPlayer && (
          <div className="media-player-wrapper">
            <div className="row">
              <div className="col-lg-6" style={{ padding: 0 }}>
                {profile && this.state.currentTrack.type === 'music' && (
                  <img style={{ borderRadius: 50, width: window.innerWidth > 768 ? window.innerWidth * 0.05 : window.innerWidth * 0.08, position: 'absolute', zIndex: 99, marginTop: 10, marginLeft: 20 }} src={this.state.profile.foto.map(item => item.publicUrl)} className="card-img-top" alt="..." />
                )}

                {profile && (
                  <MediaPlayer
                    {...{ profile }}
                    logo={this.state.profile.foto.map(item => item.publicUrl)}
                    avatarPlayer={this.state.currentTrack.picture ? this.state.currentTrack.picture : this.state.profile.foto.map(item => item.publicUrl)}
                    typePlaying={this.state.currentTrack ? this.state.currentTrack.type : null}
                    ref={c => (this._mediaPlayer = c)}
                    src={currentTrack.src}
                    autoPlay={autoPlay}
                    loop={repeatTrack}
                    currentTrack={currentTrack.label}
                    repeatTrack={repeatTrack}
                    onPrevTrack={() => this._navigatePlaylist(-1)}
                    onNextTrack={() => this._navigatePlaylist(1)}
                    onRepeatTrack={() => {
                      this.setState({ repeatTrack: !repeatTrack })
                    }}
                    onPlay={() => !autoPlay && this.setState({ autoPlay: true })}
                    onPause={() => this.setState({ autoPlay: false })}
                    onEnded={() => this.loadEnded(currentTrack)}
                  />
                )}
              </div>
              <div className="col-lg-6" style={{ padding: 0 }}>
                {profile && (
                  <Playlist
                    {...{ profile }}
                    rowsBanners={rowsBanners}
                    tracks={this.state.playlistMusic}
                    currentTrack={currentTrack}
                    onTrackClick={this._handleTrackClick}
                  />
                )}
              </div>
            </div>
          </div>
        )}
        {/*
          <button
          onClick={() => this.setState({ showMediaPlayer: !showMediaPlayer })}
        >
          Toggle Media Player
          </button>
          <VideoPlayer src="http://clips.vorwaerts-gmbh.de/big_buck_bunny.mp4" />
          <AudioPlayer src="/audio/armstrong.mp3" />
          <CirclePlayer src="https://p.scdn.co/mp3-preview/f83458d6611ae9589420f71c447ac9d2e3047cb8" />
        */}
      </div>
    )
  }
}