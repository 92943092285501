import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Media, Player, controls, utils, withMediaProps } from 'react-media-player'
import PlayPause from './PlayPause'
import MuteUnmute from './MuteUnmute'
import Repeat from './Repeat'

const {
    CurrentTime,
    Progress,
    SeekBar,
    Duration,
    Volume,
    Fullscreen,
} = controls;

const { keyboardControls } = utils

const PrevTrack = props => (
    <svg width="10px" height="12px" viewBox="0 0 10 12" {...props}>
        <polygon fill="#FAFBFB" points="10,0 2,4.8 2,0 0,0 0,12 2,12 2,7.2 10,12" />
    </svg>
)

const NextTrack = props => (
    <svg width="10px" height="12px" viewBox="0 0 10 12" {...props}>
        <polygon fill="#FAFBFB" points="8,0 8,4.8 0,0 0,12 8,7.2 8,12 10,12 10,0" />
    </svg>
)

class MediaPlayer extends Component {
    _handlePrevTrack = () => {
        this.props.onPrevTrack()
    }

    _handleNextTrack = () => {
        this.props.onNextTrack()
    }

    _handleRepeatTrack = () => {
        this.props.onRepeatTrack()
    }

    _handleEnded = () => {
        this.props.onEnded()
    }

    componentDidUpdate = () => {
    }

    render() {
        const { src, currentTrack, repeatTrack, autoPlay, profile, typePlaying, avatarPlayer, logo } = this.props;

        return (
            <Media ref={(c) => this._refMedia = c}>
                {mediaProps => (
                    <div
                        className={
                            'media-player' +
                            (mediaProps.isFullscreen ? ' media-player--fullscreen' : '')
                        }
                        onKeyDown={keyboardControls.bind(null, mediaProps)}
                        tabIndex="0"
                    >
                        <div
                            id="media-player"
                            style={{ backgroundColor: this.props.typePlaying === 'music' ? 'black' : profile.colorPlayer, height: window.innerWidth <= 768 ? '0' : '85vh' }}
                            className="media-player-element"
                            onClick={() => mediaProps.playPause()}
                        >
                            <div className="row" style={{ height: window.innerWidth <= 768 ? '32vh' : '90vh' }}>
                                {this.props.typePlaying === 'music' ?
                                    <div style={{ backgroundColor: 'black' }} className="d-flex justify-content-center align-items-center">
                                        {window.innerWidth > 768 ?
                                            <img style={{ borderRadius: 50, width: window.innerWidth * 0.4 }} src={avatarPlayer} className="card-img-top" alt="..." />
                                            :
                                            <img style={{ borderRadius: 10, width: window.innerWidth * 0.6 }} src={avatarPlayer} className="card-img-top" alt="..." />
                                        }
                                    </div>
                                    :
                                    <div className="d-flex justify-content-center align-items-center">
                                        {window.innerWidth > 768 ?
                                            <img style={{ borderRadius: 50, width: window.innerWidth * 0.25 }} src={avatarPlayer} className="card-img-top" alt="..." />
                                            :
                                            <img style={{ borderRadius: 10, width: window.innerWidth * 0.4 }} src={avatarPlayer} className="card-img-top" alt="..." />
                                        }
                                    </div>
                                }
                            </div>

                            <div className="container">
                                <Player
                                    src={src}
                                    loop={repeatTrack}
                                    autoPlay={autoPlay}
                                    onEnded={this._handleEnded}
                                    config={{
                                        youtube: { modestBranding: 1, controls: 0, start: 0, playsinline: 1 },
                                        html5: { playsinline: 1 },
                                    }}
                                />
                            </div>
                        </div>
                        <div style={{ height: window.innerWidth > 768 ? '15vh' : '15vh' }} className="media-controls media-controls--full">
                            <div className="media-row">
                                <CurrentTime className="media-control media-control--current-time" />
                                <marquee scrollamount="3">{currentTrack}</marquee>
                                <Duration className="media-control media-control--duration" />
                            </div>
                            <div className="media-control-group media-control-group--seek">
                                <Progress className="media-control media-control--progress" />
                                <SeekBar className="media-control media-control--seekbar" />
                            </div>
                            <div className="media-row">
                                <div className="media-control-group">
                                    <MuteUnmute className="media-control media-control--mute-unmute" />
                                </div>
                                <div className="media-control-group">
                                    {/*
                                    <PrevTrack
                                        className="media-control media-control--prev-track"
                                        onClick={this._handlePrevTrack}
                                    />
                                    */}

                                    <PlayPause className="media-control media-control--play-pause" />

                                    {/*
                                        <NextTrack
                                        className="media-control media-control--next-track"
                                        onClick={this._handleNextTrack}
                                    />
                                    */}
                                </div>
                                <div className="media-control-group">
                                    <Repeat
                                        className="media-control media-control--repeat"
                                        isActive={repeatTrack}
                                        onClick={this._handleRepeatTrack}
                                    />
                                    {/*
                                        <Fullscreen />
                                    */}
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </Media>
        )
    }
}

export default MediaPlayer
