import Axios from "axios";
import config from '../config';
import Qs from 'qs';
import moment from 'moment';
import { AuthToken } from './authToken';

//AuthAxios Conecta
const authAxios = Axios.create({
    baseURL: config.default.backendUrl,
    paramsSerializer: function (params) {
        return Qs.stringify(params, {
            arrayFormat: 'brackets',
            filter: (prefix, value) => {
                if (
                    value instanceof moment ||
                    value instanceof Date
                ) {
                    return value.toISOString();
                }

                return value;
            },
        });
    },
    headers: {
        'access-token': config.default.accessToken,
        'user-type': 'anonymously',
        'content-type': 'application/json'
    },
});

authAxios.interceptors.request.use(
    async function (options) {
        const token = await AuthToken.get();

        if (token) {
            options.headers['Authorization'] = `Bearer ${token}`;
        }

        options.headers['Accept-Language'] = 'es-ES';

        return options;
    },
    function (error) {
        console.log('Request error: ', error);
        return Promise.reject(error);
    },
);

//AuthAxios Arcatel Multi-Site
const authAxiosArcatelMultiSite = Axios.create({
    baseURL: config.default.backendUrlMultiSiteArcatel,
    paramsSerializer: function (params) {
        return Qs.stringify(params, {
            arrayFormat: 'brackets',
            filter: (prefix, value) => {
                if (
                    moment.isMoment(value) ||
                    value instanceof Date
                ) {
                    return value.toISOString();
                }

                return value;
            },
        });
    },
    headers: {
        'access-token': config.default.accessTokenMultiSiteArcatel,
    },
});

authAxiosArcatelMultiSite.interceptors.request.use(
    async function (options) {
        options.headers['Accept-Language'] = 'es-ES';
        return options;
    },
    function (error) {
        console.log('Request error: ', error);
        return Promise.reject(error);
    },
);

export { authAxios, authAxiosArcatelMultiSite }