import { useEffect } from 'react';

const useDfpSlot = ({ path, size, id, idContainer }) => {
    useEffect(() => {
        const googletag = window.googletag || {};

        googletag.cmd = googletag.cmd || [];
        googletag.cmd.push(() => {

            var exist = false;

            window.googletag.pubads().getSlots().map((slot) => {
                /*
                console.log(id);
                console.log(path);
                console.log(size);
                console.log(slot);
                console.log(slot.getSlotId().getId());
                console.log(slot.getSlotElementId());
                */

                if (slot.getSlotElementId() === id) {
                    googletag.pubads().refresh([slot]);
                    exist = true;
                }
            });

            if (exist === false) {
                googletag.defineSlot(path, size, id).addService(googletag.pubads());
                googletag.pubads().enableSingleRequest();
                googletag.pubads().setCentering(true);
                googletag.pubads().setTargeting(id, id);
                googletag.enableServices();
                googletag.display(id);
            }

        });
    }, []);
};

export default useDfpSlot;